export const content = {
    base_info:{
        verify_info:'Please enter complete information!',
        verify_phone:'Please enter a valid phone number!',
        submit_info:'Successfully submitted!',
        cance_info:'Successfully cancelled!',
        not_data_msg:'No more information available.',
    },
    menu: {
        create_title: 'Please stay tuned.',
        create_info: 'Under construction……',
        crosser: 'Future Crosser',
        screen: 'IMAX - like Vessel',
        future: 'Future Vessel',
        company: 'About Us',
        fos: 'Introduction to FOS',
        ar: 'Crosser APP',
        creator: '(Future) Developers Alliance',
        meta: 'Future Metaverse',
        about: 'Join Us',
        consult: 'Inquire Now',
        zj: 'See Only the Future',
        qpt: 'Future Full - screen Access',
        gywm: 'About Us',
        language: 'language',
        email: 'Service Email',
        title: 'Future.top',
        address: '10th Floor, Building 2, App Plaza, No. 8 Xiongzhuang Road, East Lake High-tech Development Zone, Wuhan, Hubei Province',
        account: 'Account',
        instructions:'Support & Download'
    },
    home: {
        lb: ['Lightweight travel fashion, with excitement coming to life in the lens', 'Future', 'Crosser', 'Book Now', 'Learn More'],
        hxys: {
            title: 'Core advantages', list: [
                { title: 'Optics', info: 'Industry-leading optics with accurate positioning and clear presentation bring smarter visual experiences.' },
                { title: 'CR mode', info: "Crossing Reality (CR), through reasonable segmentation of the user's field of view, enables the virtual and real worlds to coexist in the same space" },
                { title: 'Whole device', info: 'Efficient interaction, comfortable wear, the whole device brings an unprecedented convenient experience' },
                { title: 'Team', info: 'With a solid technical foundation and innovative thinking, the team leads the industry frontier.' },
                { title: 'Supply chain', info: 'By combining premium global resources, we achieve stability and efficiency, ensuring ongoing product innovation and iteration.' },
                { title: 'Ecosystem', info: 'Open collaboration and mutual growth inspire endless innovation possibilities, creating and sharing value together.' }
            ]
        },
        download: {
            title: 'Crosser APP',
            android_img: 'https://play.google.com/store/apps/details?id=com.fos.metaverse.overseas&pli=1',
            ios_img: 'https://apps.apple.com/us/app/future-crosser/id6479590085'
        }
    },
    crosser: {
        topText: 'AI brain crossing into the future',
        svg: 'FutureCrosser.svg',
        list: [
            { title: 'Ultra-thin and lightweight, combining style and comfort', info: 'A zero-pressure wearing experience is designed to support prolonged use.', info2: 'Forehead weight' },
            { title: 'Look up while using your phone to reduce neck strain', info: 'Looking down at your phone causes neck pressure, but crosser projects phone content onto a 200 cun screen, allowing you to look up and continue ' },
            { title1: 'Small spaces can', title2: 'hold big scenes', info1: 'Virtual imaging,', info2: 'unaffected by spatial limitations.' },
            { title1: 'Activate CR Mode', title2: 'Virtual and real-time synchronization', info: 'Virtual and real worlds displayed together, allowing you to walk and watch seamlessly.' },
            { title1: 'Support for 3D display ', title2: 'delivers a new visual impact', info: '3D display technology provides an immersive sensory experience,  fully engaging you in your personalized movies, games, and stories.' },
            { title1: "The industry's leading 'color expert'", title2: 'Define your vibrant world with unique and unparalleled color effects.', info: 'A colorful feast awaits, creating a unique visual adventure.' },
            { title1: 'Pre-adjustment', title2: 'Post-adjustment' },
            { title: 'Private and captivating audio is ensured through anti-eavesdropping and anti-disturbance features.', info: 'Clear, balanced, and captivating sound quality is consistently delivered.' },
            { title1: 'Ultra-high brightness', title2: 'Clearer for outdoor use', info: '800nit ultra-high display brightness' },
            { title1: 'Compatible with mainstream devices', title2: 'Capable of instant large-screen expansion', info: "Supports connection with external devices such as smartphones and game consoles, further expanding users' usage scenarios and enhancing their overall experience." },
            { title1: 'Easy to carry without burden', title2: 'Meets the needs of multiple scenarios', info1: 'Compact in size, easy to carry without burden', info2: 'Fulfills the usage needs of even more scenarios', info3: 'Ready for business trips and spontaneous travels, just pack and go' },
        ],
        list_img: [
            'https://img.hbcygc.net/website/v3/pc/crossers/en/list1.png', 
            'https://img.hbcygc.net/website/v3/pc/crossers/en/list2.png', 
            'https://img.hbcygc.net/website/v3/pc/crossers/en/list3.png'
        ],
        gd_list: [
            { title: 'Safe Driving', info: 'Master traffic information and embark on a new journey of safe driving.' },
            { title: 'IMAX Screening', info: 'Enjoy an extra - large screen and capture every detail.' },
            { title: 'Smart Office', info: 'Workme with high efficiency!' },
            { title: 'Fun sports games', info: 'Real - life interaction for a thrilling experience!' }
        ],
    },
    fos: {
        tree_diagram: [
            { title: 'FOS advantages reflected from appearance to core features', url: 'https://img.hbcygc.net/website/v3/pc/foses/en/ys.png' },
            { url: 'https://img.hbcygc.net/website/v3/pc/foses/en/sj.png' },
            { title: 'FOS core features — 3D', info: 'Cloud, end (or terminal), display, 3D content creation, 3D live streaming, 3D barcode standard.', url: 'https://img.hbcygc.net/website/v3/pc/foses/en/3d.png' },
            { title: 'FOS core — full-screen casting', info: 'Transform small screens into large screens through FOS', url: 'https://img.hbcygc.net/website/v3/pc/foses/en/qpt.png' },
            { title: 'FOS Core - interaction', info: 'Multiple interaction methods for seamless operation', url: 'https://img.hbcygc.net/website/v3/pc/foses/en/jh.png' }
        ],
        list1: [
            { title: 'Appearance', info: 'The display advantages of the glasses create a stunning and impactful appearance.' },
            { title: 'Interaction', info: 'The interaction experience is prioritized as the most critical aspect.' },
            { title: 'Core Competencies', info: 'Post-processing algorithms are developed based on 3D content.' },
            { title: 'Ecosystem', info: 'Third-party apps, video content, and hardware control devices are seamlessly integrated.' }
        ],
        list2: {
            title: 'Mode',
            info: [
                ['The virtual world operates without affecting reality, ', 'and reality remains unaffected by the virtual experience.'],
                ['Any optical glasses can be enhanced ', 'to deliver an immersive experience.'],
                ['The separation of virtual and reality enables ', 'smart glasses to be used for long durations.']
            ]
        }
    },
    company: {
        list1: ['Company Overview', 'Research Focus', 'Services Offered', 'Team Introduction'],
        text: ['Future.top', "Founded in 2021, future.top.is an innovative high-tech company recognized as a national high-tech enterprise, specializing in the fields of the metaverse, AR, VR/MR, and CR.", "The company currently collaborates with multiple core teams for R&D, including teams from the Chinese Academy of Sciences National Center for Nanoscience, Tsinghua University, Huazhong University of Science and Technology, Beijing Institute of Technology, Hunan University, and Ph.D. teams from the Chinese Academy of Sciences. Their focus covers three main areas: sound, light, and electricity, with in-depth design, R&D, production, and technical support in the fields of voice technology, optics, and chip integrated circuits.The company's scientists and engineers apply first principles to optimize and innovate across biophysics, involving sound, light, electricity, screens, endpoints, and networks, all while enhancing the user experience ecosystem for C-end users. At the same time, the company focuses on low-cost, high-efficiency innovation for hardware and software integration. This innovation has transformed the product into a solution that disrupts the fragmented usage of existing electronic devices, making it a comprehensive, all-day portable device for C-end users."],
        list2: ['AI & Big Data', 'Optics', 'Metaverse', 'Chip', 'Voice System'],
        tg_img: 'https://img.hbcygc.net/website/v3/pc/companys/en/yx.png',
        tg_list: ['Comprehensive Metaverse and Intelligent Hardware Services', 'Powerful R&D Team', 'Comprehensive System Integration', 'Extensive Product Integration Capabilities', 'Automated Intelligent Manufacturing and Extensive, Precision Manufacturing Experience'],
        info: 'future.top primarily focuses its R&D efforts through core teams from the Chinese Academy of Sciences, Tsinghua University, Huazhong University of Science and Technology, Beijing Institute of Technology, Hunan University, and other research teams. These teams are dedicated to the core areas of sound, light, and electricity, specifically in the design, development, production, and technical support for smart glasses hardware.',
        list3: [
            { name: 'Fu Jinlong', type: 'Founder of Future Full Screen C onnect', school: 'Huazhong University of Science and Technology' },
            { name: 'Xing Shuo', type: 'Founder of Future Full Screen C onnect', school: 'Wuhan University of Information and Communication' },
            { name: 'Jiang Changjie', type: 'Software Technology Director', school: 'Wuhan University of Technology' },
            { name: 'Wang Zhiyuan', type: 'System Technology Director', school: 'Tamkang University' }
        ]
    },
    ar: {
        title: ['Crosser APP', 'The bridge connecting the future glasses series and future OS', 'The "Crosser App" is the core launcher and user management and interaction center for Future OS, presented in the form of an app. It serves as the key to launch FOS.In addition, the Crosser App also includes a 3D space, an app store, and a marketplace.'],
        download: 'download',
        info: [
            { title: 'App Store', info: 'App ecosystem entry provides a development gateway for all metaverse players, supporting U3D development interfaces.This entry allows 3D app enthusiasts and professional 3D development teams to integrate their apps into FOS. Future application management is primarily used to manage apps that need to run on the smart glasses of the future.Apps that need to run on the Future Glasses must be downloaded, installed, and operated through the Future Application Management system.' },
            { title: '3D Space', info: 'Content ecosystem entry provides a platform for metaverse video content enthusiasts to upload 3D video content, panoramic video content, or HD video content to FOS, allowing them to enjoy their own large-screen and 3D creations in an unprecedented way.' },
            { title: 'Mall', info: 'We will provide users with an e-commerce shelf, featuring both virtual and physical goods, enabling them to experience a seamless and enhanced experience through the Future Glasses series.' },
        ]
    },
    creator: {
        title: 'The Unity SDK for Future Crosser + Android phones provides Unity developers with the tools to build AR applications,',
        btns: ['SDK Documentation', 'Download ARSDK'],
        floor: [
            {
                title: 'offering the following capabilities', info: 'The SDK is based on Google Cardboard.',
                list: ['Stereo rendering for binocular vision.', 'Future Crosser head tracking.', 'Online voice recognition.', 'Virtual touch interaction.']
            },
            { title: 'Utilizes M.vision’s stereo rendering capabilities to accurately recreate 3D scenes and objects.', info: 'Adapted to glasses through the Google Cardboard SDK.' },
            { title1: 'It uses the data from the IMU', title2: 'sensors provided by the glasses to track', title3: "user's head 3Dof", title4: 'motion and posture capabilities.', info1: 'IMU sensors based on the glasses;', info2: 'Pose algorithms based on sensor data' },
            { title1: 'The online voice solution provided by FOS ', title2: 'offers a voice interaction method for developers and users.', info1: 'Supports Chinese command words', info2: 'Recognition capabilities based on the voice recognition library', info3: 'Microphone based on Android devices' },
            { title: 'Provides touch interaction methods for developers and users', info1: 'Ring control: Connects the ring and controls via virtual rays.', info2: 'Mobile touch control: Converts the 3Dof data of the mobile phone into rays in the 3D space for control.', info3: 'Provides VRControllerInputModule for event handling and ray detection.' },
        ]
    },
    meta: {
        title: 'Core goal of creating the metaverse',
        text: [
            {
                title: 'We',
                list:
                    [
                        'The app connecting the phone and glasses—Future Center—is completed, featuring the entry point for launching FOS in the glasses, as well as entry points for the surrounding ecosystem (application entry, content entry, and consumption entry).',
                        'The FOS Metaverse framework is completed, including the design of the appearance, the presentation of the basic 3D UI, and the establishment of fundamental interactions.',
                        'External SDK interfaces are provided to establish the foundational platform for the ecosystem.'
                    ]
            },
            {
                title: 'Ecology',
                list:
                    [
                        'Application Scenarios: FOS provides third-party access interfaces, making it easy for applications to integrate, while the FOS app store supports app uploads.',
                        'Interaction Methods: Various interaction methods are enabled through FOS, including controllers, keyboards, and gesture recognition for third-party integration.'
                    ]
            }
        ],
        img: 'https://img.hbcygc.net/website/v3/pc/metas/en/dz.png'
    },
    about: {
        top: 'Consult immediately',
        name: 'Future.top',
        list: ['Phone：18908655001', 'Service Email：service@fxreye.com', 'Address：10th Floor, Building 2, App Plaza, No. 8 Xiongzhuang Road, East Lake High-tech Development Zone, Wuhan, Hubei Province', '10th Floor, Building 2, App Plaza, No. 8 Xiongzhuang Road, East Lake High-tech Development Zone, Wuhan, Hubei Province']
    },
    consult: {
        title: 'If you would like to learn more about our products, please leave your contact information.',
        list: ['Name', 'Phone', 'Message', 'Submit', 'Future.top', 'Phone', 'Service Email']
    },
    login: {
        title: 'Ignite Imagination, Empower the Future',
        text: ['Please enter your phone number.', 'Please enter the SMS verification code.', 'Send code'],
        to_url: ['I have read and agree to the', 'User Agreement', 'and', 'Privacy Policy'],
        btn_login: 'Sign In',
    },
    my: {
        title: 'Personal Center',
        menu: ['Basic Information', 'Appointment Information', 'Edit Profile', 'Log Out', 'save', 'No appointments currently','address'],
        base_info: ['Username', 'Phone', 'Birthday', 'Company', 'Job Title'],
        order_status:['Reserve','Processed','Cancelled'],
        page:['Previous Page','Next Page'],
    },
    preorder: {
        title: ['Future Crosser', 'Retail price $1000', 'Reserved information', 'We will contact you as soon as it arrives.'],
        text: [
            { title: 'Phone', info: 'Please enter your phone number' },
            { title: 'Name', info: 'Please enter your name' },
            { title: 'Address', info: 'Please enter your address' },
            { title: 'Color', info: 'Black' },
            { title: 'Purchase Quantity' }
        ],
        save: 'Submit',
        msg: ['You have successfully made an appointment. Would you like to jump to your personal center?', 'Confirm']
    },
    instructions:{
        menus:['Video Tutorial', 'FAQs (Frequently Asked Questions)', 'User Manual','Certification Report'],
        childMenus: [
            "Chinese Operating Instructions",  
            "English Operating Instructions",  
            "Promotional Video"               
          ],
          instrMenus: [
            "User Manual",                    
            "Wireless Screen Casting Report", 
            "Eyewear CE Certification",  
            "Blue Light + Flicker Test Report (CNAS)" 
          ]
    }
}